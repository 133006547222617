import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink"
    role="img" viewBox="0 0 100 100" xml="preserve">
    <title>Logo</title>
    <g>
      <path d="M1.9,43.2h1.4c4.8,0,5.6-2.2,5.6-6L8.6,25.6c0-11.3,6.3-13.5,13.7-13.5h2.1v8.8h-0.9c-3.5,0-5.1,1.3-5.1,5.6l0.3,10.3
 c0.1,6.9-2.5,10.2-6.8,11.2c4.5,1.3,6.9,4.2,6.8,11.2l-0.3,11c0,4.4,1.5,5.5,4.9,5.5h1.1v8.7h-2.4c-7.5,0-13.4-2-13.4-12.9
 l0.3-12.2c0-3.7-0.8-5.9-5.3-5.9H1.9V43.2z" fill="currentColor" />
      <path d="M39.5,78.3l12.9-62.8h8.4L47.9,78.3H39.5z" fill="currentColor" />
      <path d="M98.4,53.5H97c-4.8,0-5.6,2.2-5.6,6l0.3,11.6c0,11.3-6.3,13.5-13.7,13.5h-2.1v-8.8h0.9c3.5,0,5.1-1.3,5.1-5.6l-0.3-10.3
 c-0.1-6.9,2.5-10.2,6.8-11.2c-4.5-1.3-6.9-4.2-6.8-11.2l0.3-11c0-4.4-1.5-5.5-4.9-5.5h-1.1v-8.7h2.4c7.5,0,13.4,2,13.4,12.9
 l-0.3,12.2c0,3.7,0.8,5.9,5.3,5.9h1.7V53.5z" fill="currentColor" />
    </g>
  </svg>
);

export default IconLogo;
